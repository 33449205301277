import axios from '@axios'
import baseConfig from '@/config/index'
const TICKET_SERVICE_PATH = baseConfig.BASE_PATH
const TEST_SERVICE_PATH = baseConfig.TEST_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRefCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refCategory/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefCategoriesWithChildren(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refCategory/listWithChildren', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refCategory/detail', { refCategoryId: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRefCategory(ctx, refCategoryData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refCategory/edit', refCategoryData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRefCategory(ctx, refCategoryData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refCategory/delete', refCategoryData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRefCategory(ctx, refCategoryData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refCategory/create', refCategoryData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
